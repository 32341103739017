import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src="./logo.png" className="App-logo" alt="logo" />
        <h1>Crowsure</h1>
        <p>
        experience research - experience design - service design 
        </p>
        <a className='button' href="mailto:lorinc.kiss@crowsure.com">Contact us!</a>
      </header>
    </div>
  );
}

export default App;
